import { generatePartnerEventCall } from "./events";

export type AnalyticsMethods =
  | "onLoad"
  | "onClose"
  | "onOpen"
  | "onPermanentClose"
  | "onFailToLoadConfig"
  | "onActivate"
  | "onInstallClick"
  | "onFooterOpen"
  | "onFooterClose"
  | "onFooterLoad"
  | "onFooterPermanentClose"
  | "charityDirectoryLink";
type EventData = Record<string, any>;

export type AnalyticsUrlsRecord = Partial<Record<AnalyticsMethods, string>>;
export type Analytics = Record<
  AnalyticsMethods,
  (eventData?: EventData) => void
>;
export type AnalyticsMethod = (
  url: string,
  eventsApiCall: (
    eventType: AnalyticsMethods,
    eventData?: EventData
  ) => Promise<void>,
  eventData?: EventData
) => Promise<void>;

const makeGetRequest = async (url: string) => {
  try {
    await fetch(url);
  } catch (error) {
    console.error(`Failed to fetch from ${url}:`, error);
  }
};

const onLoad: AnalyticsMethod = async (url, eventsApiCall, eventData) => {

  await eventsApiCall("onLoad", eventData);
  await makeGetRequest(url);
};

const onFooterLoad: AnalyticsMethod = async (url, eventsApiCall, eventData) => {
  await eventsApiCall("onFooterLoad", eventData);
  await makeGetRequest(url);
}

const onClose: AnalyticsMethod = async (url, eventsApiCall, eventData) => {
  await eventsApiCall("onClose", eventData);
  await makeGetRequest(url);
};

const onFooterClose: AnalyticsMethod = async (url, eventsApiCall, eventData) => {
  await eventsApiCall("onFooterClose", eventData);
  await makeGetRequest(url);
}

const onOpen: AnalyticsMethod = async (url, eventsApiCall, eventData) => {
  await eventsApiCall("onOpen", eventData);
  await makeGetRequest(url);
};

const onFooterOpen: AnalyticsMethod = async (url, eventsApiCall, eventData) => {
  await eventsApiCall("onFooterOpen", eventData);
  await makeGetRequest(url);
}

const onActivate: AnalyticsMethod = async (_, eventsApiCall, eventData) => {
  await eventsApiCall("onActivate", eventData);
}

const onInstallClick: AnalyticsMethod = async (_, eventsApiCall, eventData) => {
  await eventsApiCall("onInstallClick", eventData);
}

const onPermanentClose: AnalyticsMethod = async (
  url,
  eventsApiCall,
  eventData
) => {
  // ... other logic
  await eventsApiCall("onPermanentClose", eventData);
  await makeGetRequest(url);
};

const onFooterPermanentClose: AnalyticsMethod = async (
  url,
  eventsApiCall,
  eventData
) => {
  await eventsApiCall("onFooterPermanentClose", eventData);
  await makeGetRequest(url);
}

const onFailToLoadConfig: AnalyticsMethod = async (
  url,
  eventsApiCall,
  eventData
) => {
  // ... other logic
  await eventsApiCall("onFailToLoadConfig", eventData);
  await makeGetRequest(url);
};

const charityDirectoryLink: AnalyticsMethod = async (
  url,
  eventsApiCall,
  eventData
) => {
  // to be impleented
}

export const getAnalytics = (urlMap: AnalyticsUrlsRecord, partner: string) => {
  return Object.entries(urlMap).reduce((acc, curr) => {
    const method = curr[0] as AnalyticsMethods;
    const url = curr[1];
    const eventApiCall = generatePartnerEventCall(partner);
    const methodToFunction = {
      onLoad,
      onClose,
      onOpen,
      onPermanentClose,
      onFailToLoadConfig,
      onActivate,
      onInstallClick,
      onFooterOpen,
      onFooterClose,
      onFooterLoad,
      onFooterPermanentClose,
      charityDirectoryLink
    };

    return {
      ...acc,
      [method]: (eventData?: EventData) =>
        methodToFunction[method](url, eventApiCall, eventData)
    };
  }, {} as Analytics);
};
