import {
  getAnalytics,
  type AnalyticsUrlsRecord,
  type Analytics
} from "../services/analytics";
import { getConfig } from "../services/resources";
import { renderPopup, setButton, setElement } from "../utils/renders";
import {
  isChromeBased,
  isCorrectPage,
  isFilterBypassed,
  isFirstSessionView,
  isMobile,
  wasNotShowAgainClicked,
  isValidCountryIp,
  wasShownInDay,
  wasShownInWeek,
  wasShownInMonth,
  isInRollOut,
  markVisitor,
  setFirstSessionView
} from "../utils/verifications";
import { renderStickyFooter } from "./stickyFooter";

export type Hooks = {
  onRollout?: (isInRollout: boolean) => void;
  countriesToShow?: string[] | undefined;
};

/**
 * CONTENTS is a string that contains the HTML for the popup
 * It is generated by the build process and injected into the bundle
 */
declare const CONTENTS: string;

export const createPopup = async (
  configUrl: string,
  defaultConfig: Config | null,
  analyticsUrls: AnalyticsUrlsRecord,
  analyticsEventId: string,
  next?: (
    config: Config,
    container: HTMLDivElement,
    analytics: Analytics
  ) => void,
  hooks?: Hooks
) => {
  const analytics = getAnalytics(analyticsUrls, analyticsEventId);

  const filterByViews = (
    shownInWeek: number,
    shownInMonth: number,
    config: Config,
    bypassFilters: boolean,
    hooks?: Hooks
  ) => {
    if (wasNotShowAgainClicked() && !bypassFilters) return false;
    if (wasShownInDay() && !bypassFilters) return false;
    if (shownInWeek >= config.repeatInAWeek && !bypassFilters) return false;
    if (shownInMonth >= config.repeatInAMonth && !bypassFilters) return false;
    const inRollout = isInRollOut(config.rolloutPercentage);
    hooks?.onRollout?.(inRollout);
    if (!inRollout && !bypassFilters) return false;
    return true;
  };

  const addPopup = async (
    contents: string,
    config: Config,
    hooks?: Hooks
  ): Promise<boolean> => {
    const bypassFilters = isFilterBypassed();
    // Return if the popup is off
    if (!config.isOn && !bypassFilters) return false;

    // Filter by user
    if (!isChromeBased() && !bypassFilters) return false;
    if (isMobile() && !bypassFilters) return false;
    const isValidCountry = await isValidCountryIp(hooks?.countriesToShow);
    if (!isValidCountry && !bypassFilters) return false;

    // Filter by views
    const shownInWeek = wasShownInWeek() ?? 0;
    const shownInMonth = wasShownInMonth() ?? 0;
    const showPopup =
      (filterByViews(shownInWeek, shownInMonth, config, bypassFilters, hooks) &&
        isCorrectPage(config.pageMatch)) ||
      bypassFilters;

    // Mark visitor after all filters
    if (showPopup) {
      markVisitor(config.hoursToShow, shownInWeek, shownInMonth);
    }

    // Render elements
    const container = renderPopup(
      contents,
      showPopup,
      analytics,
      "gf-close-btn",
      "gf-block-close-btn",
      config
    );
    setButton("gf-open-btn", config.wording.cta, () => {
      window.open(config.installLink, "_blank");
      analytics.onInstallClick();
      const popup = document.getElementById("gf-popup");
      popup?.remove();
    });
    setElement("gf-title", config.wording.title);
    setElement("gf-copy", config.wording.copy);

    // Execute next callback function if it exists
    next?.(config, container, analytics);
    return true;
  };

  analytics.onLoad();
  const config = await getConfig(configUrl, defaultConfig, analytics);
  if (!config) return;
  const activationAnalytics = await buildOnActivateData(config, hooks);
  analytics.onActivate(activationAnalytics);
  if (isFirstSessionView()) {
    setFirstSessionView();
    setTimeout(async () => {
      addPopup(CONTENTS, config, hooks);
    }, config.millisecondsDelayedBeforePop);
  } else {
    addPopup(CONTENTS, config, hooks);
  }
};

export const createStickyFooter = (
  footerId: string,
  closeBtnId: string,
  urls: AnalyticsUrlsRecord,
  config?: Config,
) => {
  renderStickyFooter(CONTENTS, footerId, closeBtnId, urls, config);
};

const buildOnActivateData = async (config: Config, hooks?: Hooks) => {
  const isValidCountry = await isValidCountryIp(hooks?.countriesToShow);
  return {
    bypassFilterOn: isFilterBypassed(),
    wasShownInDay: wasShownInDay() ?? false,
    shownInWeek: wasShownInWeek() ?? 0,
    shownInMonth: wasShownInMonth() ?? 0,
    rolloutPercentage: isInRollOut(config.rolloutPercentage),
    millisecondsDelayedBeforePop: config.millisecondsDelayedBeforePop,
    isFirstSessionView: isFirstSessionView(),
    isOn: config.isOn,
    pageMatch: isCorrectPage(config.pageMatch),
    isChromiumBased: isChromeBased(),
    isMobile: isMobile(),
    isValidCountry: isValidCountry,
    cookiesEnabled: window.navigator.cookieEnabled,
    isSessionStorageAccessible: !!window.sessionStorage,
    isLocalStorageAccessible: !!window.localStorage
  };
};
