export const GF_POPUP_SHOWN_IN_DAY = "gf_popup_shown_in_day";
export const GF_POPUP_SHOWN_IN_WEEK = "gf_popup_shown_in_week";
export const GF_POPUP_SHOWN_IN_MONTH = "gf_popup_shown_in_month";
export const GF_POPUP_SHOWN_IN_WEEK_EXPIRY = "gf_popup_shown_in_week_expiry";
export const GF_POPUP_SHOWN_IN_MONTH_EXPIRY = "gf_popup_shown_in_month_expiry";
export const GF_POPUP_NOT_SHOW = "gf_popup_not_show";
export const GF_FOOTER_NOT_SHOW = "gf_footer_not_show";
export const GF_IS_VALID_COUNTRY_IP = "gf_is_valid_country_ip";
export const GF_SESSION_VIEW = "gf_session_view";
export const GF_ROLLOUT_NUMBER = "gf_rollout_number";
export const IPAPI_URL = "https://pro.ip-api.com/json/";
export const GF_IDENTIFIER = "gf_identifier";
export const GF_SESSION_ID = "gf_session_id";
