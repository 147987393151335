import { GF_FOOTER_NOT_SHOW, GF_POPUP_NOT_SHOW } from "./constants";
import { setCookie } from "./cookies";
import { expirationMaxTime } from "./verifications";

export const dismissFooter = (
  stickyFooter: HTMLElement,
  expiration: number
) => {
  stickyFooter.remove();
  setCookie(GF_FOOTER_NOT_SHOW, true, expiration);
};

export const dismissPopup = (popup: HTMLElement, expiration: number) => {
  popup.remove();
  setCookie(GF_POPUP_NOT_SHOW, true, expiration);
};

export const dismissForever = (elements: {
  popup?: HTMLElement | null;
  stickyFooter?: HTMLElement | null;
}) => {
  elements.popup && elements.popup.remove();
  elements.stickyFooter && elements.stickyFooter.remove();

  setCookie(GF_FOOTER_NOT_SHOW, true, expirationMaxTime);
  setCookie(GF_POPUP_NOT_SHOW, true, expirationMaxTime);
};
