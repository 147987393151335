import type { Analytics } from "./analytics";

export const getConfig = async (
  configURL: string,
  defaultConfig: Config | null,
  analytics?: Analytics
) => {
  try {
    const response = await fetch(configURL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        mode: "no-cors"
      }
    });
    return (await response.json()) as Config;
  } catch (error) {
    console.error("Failed to fetch config file:", error);
    analytics?.onFailToLoadConfig({ error });
    return defaultConfig;
  }
};
