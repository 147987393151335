import { type Analytics } from "../services/analytics";
import { setStickyFooter } from "./stickyFooter";

export const renderPopup = (
  contents: string,
  showPopup: boolean,
  analytics: Analytics,
  closeBtnId?: string,
  backdropId?: string,
  config?: Config
): HTMLDivElement => {
  const container = document.createElement("div");
  container.innerHTML = contents;
  document.body.appendChild(container);

  const popup = document.getElementById("gf-popup");
  if (!showPopup) {
    popup?.remove();
    setStickyFooter("gf-sticky-footer", "gf-close-footer-btn",analytics, config);
    return container;
  }
  analytics?.onOpen();

  if (closeBtnId) {
    const closeBtn = document.querySelectorAll(`#${closeBtnId}`);
    closeBtn.forEach((element) => {
      element.addEventListener("click", () => {
        analytics?.onClose();
        popup?.remove();
        setStickyFooter("gf-sticky-footer", "gf-close-footer-btn", analytics, config);
      });
    });
  }

  if (backdropId) {
    const backdrop = document.querySelector(`#${backdropId}`);
    backdrop?.addEventListener("click", (e) => {
      e.stopPropagation();
    });
  }

  return container;
};

export const setElement = (linkId: string, text: string, href?: string) => {
  const element = document.querySelector(`#${linkId}`);
  element && (element.innerHTML = text);
  if (!href) return;
  element?.setAttribute("href", href);
};

export const setButton = (
  buttonId: string,
  text: string,
  onClick: () => void
) => {
  const button = document.querySelector(`#${buttonId}`);
  button && (button.innerHTML = text);
  button?.addEventListener("click", onClick);
};
